exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-marketing-adatkezelesi-tajekoztato-tsx": () => import("./../../../src/pages/marketing/adatkezelesi-tajekoztato.tsx" /* webpackChunkName: "component---src-pages-marketing-adatkezelesi-tajekoztato-tsx" */),
  "component---src-pages-marketing-eredmenyessegmeres-tsx": () => import("./../../../src/pages/marketing/eredmenyessegmeres.tsx" /* webpackChunkName: "component---src-pages-marketing-eredmenyessegmeres-tsx" */),
  "component---src-pages-marketing-megvalositas-tsx": () => import("./../../../src/pages/marketing/megvalositas.tsx" /* webpackChunkName: "component---src-pages-marketing-megvalositas-tsx" */),
  "component---src-pages-marketing-novekedesi-strategia-kidolgozasa-tsx": () => import("./../../../src/pages/marketing/novekedesi-strategia-kidolgozasa.tsx" /* webpackChunkName: "component---src-pages-marketing-novekedesi-strategia-kidolgozasa-tsx" */),
  "component---src-pages-marketing-oktatas-tanacsadas-tsx": () => import("./../../../src/pages/marketing/oktatas-tanacsadas.tsx" /* webpackChunkName: "component---src-pages-marketing-oktatas-tanacsadas-tsx" */),
  "component---src-pages-marketing-referenciak-tsx": () => import("./../../../src/pages/marketing/referenciak.tsx" /* webpackChunkName: "component---src-pages-marketing-referenciak-tsx" */),
  "component---src-pages-marketing-rolunk-tsx": () => import("./../../../src/pages/marketing/rolunk.tsx" /* webpackChunkName: "component---src-pages-marketing-rolunk-tsx" */),
  "component---src-pages-marketing-szemelyre-szabott-diagnosztika-tsx": () => import("./../../../src/pages/marketing/szemelyre-szabott-diagnosztika.tsx" /* webpackChunkName: "component---src-pages-marketing-szemelyre-szabott-diagnosztika-tsx" */),
  "component---src-pages-marketing-szolgaltatasok-tsx": () => import("./../../../src/pages/marketing/szolgaltatasok.tsx" /* webpackChunkName: "component---src-pages-marketing-szolgaltatasok-tsx" */),
  "component---src-pages-marketing-tsx": () => import("./../../../src/pages/marketing.tsx" /* webpackChunkName: "component---src-pages-marketing-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-weboldal-adatkezelesi-tajekoztato-tsx": () => import("./../../../src/pages/weboldal/adatkezelesi-tajekoztato.tsx" /* webpackChunkName: "component---src-pages-weboldal-adatkezelesi-tajekoztato-tsx" */),
  "component---src-pages-weboldal-igy-dolgozunk-tsx": () => import("./../../../src/pages/weboldal/igy-dolgozunk.tsx" /* webpackChunkName: "component---src-pages-weboldal-igy-dolgozunk-tsx" */),
  "component---src-pages-weboldal-referenciak-tsx": () => import("./../../../src/pages/weboldal/referenciak.tsx" /* webpackChunkName: "component---src-pages-weboldal-referenciak-tsx" */),
  "component---src-pages-weboldal-rolunk-tsx": () => import("./../../../src/pages/weboldal/rolunk.tsx" /* webpackChunkName: "component---src-pages-weboldal-rolunk-tsx" */),
  "component---src-pages-weboldal-tsx": () => import("./../../../src/pages/weboldal.tsx" /* webpackChunkName: "component---src-pages-weboldal-tsx" */)
}

